import React from "react"
// src/App.js
import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Destacados, Intro, Ratings, Footer, About, PrivacidadEs, PrivacidadEn, Selectoridioma, Inicio, Chatbot, Juegos, Destacados2, Clasificaciones, PrivacyPolicy } from './components';
import { useTranslation } from 'react-i18next';
import { CookiesProvider, useCookies } from './CookiesContext';
import CookieModal from './components/CookieModal';
import i18n from './i18n';

const HomePage = () => {
    const { cookies, isFirstVisit, cookiesAccepted } = useCookies();

    React.useEffect(() => {
        if (isFirstVisit) {
            console.log('Primera visita detectada, mostrando modal de cookies.');
        } else {
            console.log('Bienvenido de nuevo!');
        }
    }, [isFirstVisit]);

    return (
        <div>
            <div className='Navbar'><Navbar /></div>
            <div className="Intro"><Intro /></div>
            <div className="Ratings"><Ratings /></div>
            <div className="Destacados"><Destacados /></div>
            <div className="Destacados2"><Destacados2 /></div>
            <div className='Footer'><Footer /></div>
            <div className='Chatbot'><Chatbot /></div>
            {isFirstVisit && !cookiesAccepted && <CookieModal />}
        </div>
    );
}

const GamesPage = () => (
    <div>
        <div className='Navbar'><Navbar /></div>
        <div className="Juegos"><Juegos /></div>
        <div className='footer'><Footer /></div>
        <div className='Chatbot'><Chatbot /></div>
    </div>
);

const RankingsPage = () => (
    <div>
        <div className='Navbar'><Navbar /></div>
        <div className="Clasificaciones"><Clasificaciones /></div>
        <div className='footer'><Footer /></div>
        <div className='Chatbot'><Chatbot /></div>
    </div>
);

const AboutUsPage = () => (
    <div>
        <div className='Navbar'><Navbar /></div>
        <div className="About"><About /></div>
        <div className='footer'><Footer /></div>
        <div className='Chatbot'><Chatbot /></div>
    </div>
);

const PrivacyEsPage = () => (
    <div>
        <div className='Navbar'><Navbar /></div>
        <div className="PrivacidadEs"><PrivacidadEs /></div>
        <div className='footer'><Footer /></div>
    </div>
);

const PrivacyEnPage = () => (
    <div>
        <div className='Navbar'><Navbar /></div>
        <div className="PrivacidadEn"><PrivacidadEn /></div>
        <div className='footer'><Footer /></div>
    </div>
);

const PrivacyPolicyPage = () => (
    <div>
        <div className='Navbar'><Navbar /></div>
        <div className="PrivacyPolicy"><PrivacyPolicy /></div>
        <div className='footer'><Footer /></div>
    </div>
);

const App = () => {
    const { t } = useTranslation();
    return (
        <CookiesProvider>
            <Router>
                <div className="min-h-screen">
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/games' element={<GamesPage />} />
                        <Route path='/rankings' element={<RankingsPage />} />
                        <Route path='/aboutus' element={<AboutUsPage />} />
                        <Route path='/privacy_es.html' element={<PrivacyEsPage />} />
                        <Route path='/privacy_en.html' element={<PrivacyEnPage />} />
                        <Route path='/privacy_policy' element={<PrivacyPolicyPage />} />
                    </Routes>
                </div>
            </Router>
        </CookiesProvider>
    );
}

export default App;