import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { TextField, Button, IconButton, Fab } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import AssistantIcon from '@mui/icons-material/Assistant';
import { useTranslation } from 'react-i18next';

const Chatbot = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [error, setError] = useState(null);
    const [chatId, setChatId] = useState("");
    const messagesEndRef = useRef(null);
    

    const initialMessage = {
        role: "system",
        content: t('chat3')
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = async () => {
        if (input.trim() === "") return;

        const userMessage = { role: "user", content: input };
        const updatedMessages = [...messages, userMessage];
        setMessages(updatedMessages);
        setInput("");

        try {
            const response = await axios.post("https://overlord.chat/api/v1/chat/", {
                clientCode: "quarzoapps",  
                chatId: chatId,
                message: input,
            }, {
                headers: {
                    "Content-Type": "application/json",
                }
            });

            const { chatId: newChatId, message: botMessageContent } = response.data;
            setChatId(newChatId);
            const botMessage = { role: "assistant", content: botMessageContent };
            setMessages([...updatedMessages, botMessage]);
        } catch (error) {
            console.error("Error communicating with the server:", error);
            setError("Error communicating with the server. Please try again later.");
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSend();
        }
    };

    return (
        <div>
            <Fab 
                onClick={handleToggle} 
                style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    zIndex: 1000,
                    backgroundColor: "#fff", 
                    color: "#000", 
                }}
            >
                <AssistantIcon style={{ color: "#000" }} /> 
            </Fab>
            {open && (
                <div style={{ 
                    position: "fixed", 
                    bottom: "80px", 
                    right: "20px", 
                    width: "350px", 
                    height: "500px", 
                    backgroundColor: "#fff", 
                    padding: "10px", 
                    borderRadius: "10px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    zIndex: 1000,
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <div style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center', 
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                    }}>
                        <h4 style={{ margin: 0 }}>Quarzo AI</h4>
                        <IconButton onClick={handleClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ 
                        flex: 1,
                        overflowY: "auto", 
                        padding: "10px",
                        marginBottom: "10px",
                    }}>
                        {/* Initial Message */}
                        <div 
                            style={{ 
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                        >
                            <div 
                                style={{ 
                                    background: "#e5e5ea", 
                                    color: "#000", 
                                    padding: "10px", 
                                    borderRadius: "10px", 
                                    maxWidth: "80%",
                                }}
                            >
                                {initialMessage.content}
                            </div>
                        </div>
                        {/* User and Bot Messages */}
                        {messages.map((msg, index) => (
                            <div 
                                key={index} 
                                style={{ 
                                    marginBottom: "10px",
                                    display: "flex",
                                    justifyContent: msg.role === "user" ? "flex-end" : "flex-start",
                                }}
                            >
                                <div 
                                    style={{ 
                                        background: msg.role === "user" ? "#0b93f6" : "#e5e5ea", 
                                        color: msg.role === "user" ? "#fff" : "#000", 
                                        padding: "10px", 
                                        borderRadius: "10px", 
                                        maxWidth: "80%",
                                    }}
                                >
                                    {msg.content}
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <div style={{ 
                        display: "flex", 
                        alignItems: "center", 
                        padding: "10px",
                        borderTop: '1px solid #ddd',
                    }}>
                        <TextField
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder={t('chat1')}
                            variant="outlined"
                            size="small"
                            style={{ flex: 1, marginRight: "10px" }}
                            onKeyPress={handleKeyPress}
                        />
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleSend}
                            endIcon={<SendIcon />}
                        >
                            {t('chat2')}
                        </Button>
                    </div>
                    {error && <p style={{ color: 'red', padding: '10px' }}>{error}</p>}
                </div>
            )}
        </div>
    );
}

export default Chatbot;